/* eslint-disable react/prop-types */
import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import URLSearchParams from "url-search-params";
import {Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import { ConfigProvider } from "antd";
import {IntlProvider} from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import VerifyPacient from "../VerifyPacient";
import Descarga from "../Descarga";
import Safety from "../Safety";
import {setInitUrl} from "appRedux/actions/Auth";
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "appRedux/actions/Setting";

import {
	LAYOUT_TYPE_BOXED,
	LAYOUT_TYPE_FRAMED,
	LAYOUT_TYPE_FULL,
	NAV_STYLE_ABOVE_HEADER,
	NAV_STYLE_BELOW_HEADER,
	NAV_STYLE_DARK_HORIZONTAL,
	NAV_STYLE_DEFAULT_HORIZONTAL,
	NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";

const RestrictedRoute = ({component: Component, location, token, ...rest}) =>
	<Route
		{...rest}
		render={props =>
		/* token
        ?  */
			<Component {...props} />
			/* : <Redirect
          to={{
            pathname: '/signin',
            state: {from: location}
          }} 
        />*/
		}
	/>;


const App = () => {

	const dispatch = useDispatch();
	const {locale, navStyle, layoutType} = useSelector(({settings}) => settings);
	const {token, initURL} = useSelector(({auth}) => auth);


	const location = useLocation();
	const history = useHistory();
	const match = useRouteMatch();


	useEffect(() => {
		let link = document.createElement("link");
		link.type = "text/css";
		link.rel = "stylesheet";
		link.href = "/css/style.css";
		link.className = "gx-style";
		document.body.appendChild(link);
	});

	useEffect(() => {
		if (initURL === "") {
			dispatch(setInitUrl(location.pathname));
		}
		const params = new URLSearchParams(location.search);

		if (params.has("theme")) {
			dispatch(setThemeType(params.get("theme")));
		}
		if (params.has("nav-style")) {
			dispatch(onNavStyleChange(params.get("nav-style")));
		}
		if (params.has("layout-type")) {
			dispatch(onLayoutTypeChange(params.get("layout-type")));
		}
		setLayoutType(layoutType);
		setNavStyle(navStyle);
	}, [dispatch, initURL, layoutType, location.pathname, location.search, navStyle]);

	const setLayoutType = (layoutType) => {
		if (layoutType === LAYOUT_TYPE_FULL) {
			document.body.classList.remove("boxed-layout");
			document.body.classList.remove("framed-layout");
			document.body.classList.add("full-layout");
		} else if (layoutType === LAYOUT_TYPE_BOXED) {
			document.body.classList.remove("full-layout");
			document.body.classList.remove("framed-layout");
			document.body.classList.add("boxed-layout");
		} else if (layoutType === LAYOUT_TYPE_FRAMED) {
			document.body.classList.remove("boxed-layout");
			document.body.classList.remove("full-layout");
			document.body.classList.add("framed-layout");
		}
	};

	const setNavStyle = (navStyle) => {
		if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
			document.body.classList.add("full-scroll");
			document.body.classList.add("horizontal-layout");
		} else {
			document.body.classList.remove("full-scroll");
			document.body.classList.remove("horizontal-layout");
		}
	};

	/* useEffect(() => {
    if (location.pathname === '/') {
      if (token === null) {
        history.push('/appointment');
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        console.log("pruebassssss");
        if(user){
          console.log("pruebassssss"+ JSON.stringify(user));
          if(user.af){
            if(location.pathname == "/account"){
    
            }else{
            history.push('/appointment');
            }
          }else{
            history.push(initURL);
          }
        }
      } else {
        if(user){
          if(user.af){
            if(location.pathname == "/account"){
    
            }else{
            history.push('/appointment');
            }
          }
        }
      }
    }
  }, [token, initURL, location, history]); */

	useEffect(() => {
		if (location.pathname === "/") {
			if (token === null) {
				history.push("/appointment");
			} else if (initURL === "" || initURL === "/" || initURL === "/signin") {
				history.push("/appointment");
			} else {
				history.push(initURL);
			}
		}
	}, [token, initURL, location, history]);

	const currentAppLocale = AppLocale[locale.locale];

	return (
		<ConfigProvider locale={currentAppLocale.antd}>
			<IntlProvider
				locale={currentAppLocale.locale}
				messages={currentAppLocale.messages}>

				<Switch>
					{/*<Route exact path='/signin' component={SignIn}/>
          <Route exact path='/signup' component={SignUp}/>*/}
					<Route exact path='/pacient/verify/:token' component={VerifyPacient}/>
					<Route exact path='/descargapp' component={Descarga}/>
					<Route exact path='/safety' component={Safety}/>
					<RestrictedRoute path={`${match.url}`} token={token} location={location}
						component={MainApp}/>
				</Switch>
			</IntlProvider>
		</ConfigProvider>
	);
};

export default memo(App);
