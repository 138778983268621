import {
	FETCH_ERROR_VARIABLES,
	FETCH_START_VARIABLES,
	FETCH_SUCCESS_VARIABLES,
	INIT_URL,
	SET_VARIABLES
} from "constants/ActionTypes";
import axios from "util/Api";
  
export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};
  
export const getVariables = () => {
	return (dispatch) => {
		dispatch({type: FETCH_START_VARIABLES});
      
		axios.get("variablesGlobal").then(({data}) => {

			if (data) {
				dispatch({type: FETCH_SUCCESS_VARIABLES});
				dispatch({type: SET_VARIABLES, payload: data.variables});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR_VARIABLES, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_VARIABLES, payload: error});
		});
	};
};
