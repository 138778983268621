import {INIT_URL, OPTIONS_SET, OPTIONS_RESET, OPTIONS_SET_ISDIRECTION, OPTIONS_SET_HORARIO, OPTIONS_REQUIRED_LOGIN, COINCIDENCES_SET, 
FETCH_START_AUTOCOMPLETE, NEW_SEARCH, FETCH_SUCCESS_AUTOCOMPLETE, FETCH_ERROR_AUTOCOMPLETE, FILTERS_SET, FILTERS_RESET, FETCH_START_FILTERS, FETCH_SUCCESS_FILTERS, FETCH_ERROR_FILTERS, REFRESH_ALL_SEARCH } from "../../constants/ActionTypes";

const INIT_STATE = {
	initURL: "",
	options: null,
	action: [[],[]],
	user: null,
	isDirection: null,
	select_horario: null,
	required_login: false,
	coincidences: null,
	error: "",
	loading: false,
	message: "",
	filters: "",
	error_filter: "",
	loading_filter: false,
	message_filter: "",
	new_search: false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

	case INIT_URL: {
		return {...state, initURL: action.payload};
	}

	case OPTIONS_SET: {
		return {
			...state,
			options: action.payload,
		};
	}

	case NEW_SEARCH: {
		return {
			...state,
			new_search: action.payload,
		};
	}

	case COINCIDENCES_SET: {
		return {
			...state,
			coincidences: action.payload,
		};
	}
	case OPTIONS_RESET: {
		return {
			...state,
			options: null
		};
	}
	case FILTERS_SET: {
		return {
			...state,
			filters: action.payload,
		};
	}
	case FILTERS_RESET: {
		return {
			...state,
			filters: null
		};
	}
	case OPTIONS_SET_ISDIRECTION: {
		return {
			...state,
			isDirection: action.payload
		};
	}
	case OPTIONS_SET_HORARIO: {
		return {
			...state,
			select_horario: action.payload
		};
	}
    
	case OPTIONS_REQUIRED_LOGIN: {
		return {
			...state,
			required_login: action.payload,
		};
	}

	case FETCH_START_AUTOCOMPLETE: {
		return {...state, error: "", message: "", loading: true};
	}
	case FETCH_SUCCESS_AUTOCOMPLETE: {
		return {...state, error: "", message: "", loading: false};
	}
	case FETCH_ERROR_AUTOCOMPLETE: {
		return {...state, loading: false, error: action.payload, message: ""};
	}

	case FETCH_START_FILTERS: {
		return {...state, error_filter: "", message_filter: "", loading_filter: true};
	}
	case FETCH_SUCCESS_FILTERS: {
		return {...state, error_filter: "", message_filter: "", loading_filter: false};
	}
	case FETCH_ERROR_FILTERS: {
		return {...state, loading_filter: false, error_filter: action.payload, message_filter: ""};
	}
	case REFRESH_ALL_SEARCH : {
		return {...state, loading_filter: false, error_filter: action.payload, message_filter: ""}; 
	}
	default:
		return state;
	}
};
