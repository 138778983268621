// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const FETCH_ACTION = "FETCH_ACTION";
export const FETCH_ACTIVE = "FETCH_ACTIVE";  
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";
export const RESET_ACTION_COMMON = "RESET_ACTION_COMMON";
export const REFRESH_ALL_SEARCH = "RESET_ACTION_COMMON";
export const FILTERS_SET = "FILTERS_SET";
export const FILTERS_RESET = "FILTERS_RESET";
export const FETCH_START_FILTERS = "FETCH_START_FILTERS";
export const FETCH_SUCCESS_FILTERS = "FETCH_SUCCESS_FILTERS";
export const FETCH_ERROR_FILTERS = "FETCH_ERROR_FILTERS";

export const FETCH_START_AUTOCOMPLETE = "FETCH_START_AUTOCOMPLETE";
export const FETCH_SUCCESS_AUTOCOMPLETE = "FETCH_SUCCESS_AUTOCOMPLETE";
export const FETCH_ERROR_AUTOCOMPLETE = "FETCH_ERROR_AUTOCOMPLETE";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";

//PACIENTS
export const OPTIONS_SET = "OPTIONS_SET";
export const COINCIDENCES_SET  = "COINCIDENCES_SET";
export const OPTIONS_RESET = "OPTIONS_RESET";
export const OPTIONS_SET_ISDIRECTION = "OPTIONS_SET_ISDIRECTION";
export const OPTIONS_SET_HORARIO = "OPTIONS_SET_HORARIO";
export const OPTIONS_REQUIRED_LOGIN = "OPTIONS_REQUIRED_LOGIN";
export const NEW_SEARCH = "NEW_SEARCH";

//AUTH
export const FETCH_START_LOGIN = "FETCH_START_LOGIN";
export const FETCH_SUCCESS_LOGIN = "FETCH_SUCCESS_LOGIN";
export const FETCH_ERROR_LOGIN = "FETCH_ERROR_LOGIN";

//AUTH
export const FETCH_START_REGISTER = "FETCH_START_REGISTER";
export const FETCH_SUCCESS_REGISTER = "FETCH_SUCCESS_REGISTER";
export const FETCH_ERROR_REGISTER = "FETCH_ERROR_REGISTER";
export const SET_ACTION_REGISTER = "SET_ACTION_REGISTER";

//USER
export const FETCH_START_ACCOUNT_USER = "FETCH_START_ACCOUNT_USER";
export const FETCH_SUCCESS_ACCOUNT_USER = "FETCH_SUCCESS_ACCOUNT_USER";
export const FETCH_ERROR_ACCOUNT_USER = "FETCH_ERROR_ACCOUNT_USER";
export const SET_ACTION_ACCOUNT_USER = "SET_ACTION_ACCOUNT_USER";
export const SET_ACCOUNT_USER = "SET_ACCOUNT_USER";

//ORDER
export const SET_CURRENT_ORDER = "SET_CURRENT_ORDER";

//SUBACCOUNT
export const FETCH_START_SUBACCOUNT_USER = "FETCH_START_SUBACCOUNT_USER";
export const FETCH_SUCCESS_SUBACCOUNT_USER = "FETCH_SUCCESS_SUBACCOUNT_USER";
export const FETCH_ERROR_SUBACCOUNT_USER = "FETCH_ERROR_SUBACCOUNT_USER";
export const SET_ACTION_SUBACCOUNT_USER = "SET_ACTION_SUBACCOUNT_USER";
export const SET_SUBACCOUNT_USERS = "SET_SUBACCOUNT_USERS";

//ORDER
export const FETCH_START_ORDER = "FETCH_START_ORDER";
export const FETCH_SUCCESS_ORDER = "FETCH_SUCCESS_ORDER";
export const FETCH_ERROR_ORDER = "FETCH_ERROR_ORDER";
export const SET_ACTION_ORDER = "SET_ACTION_ORDER";

//PRESCRIPTIONS
export const FETCH_ERROR_PRESCRIPTIONS = "FETCH_ERROR_PRESCRIPTIONS";
export const FETCH_START_PRESCRIPTIONS = "FETCH_START_PRESCRIPTIONS";
export const FETCH_SUCCESS_PRESCRIPTIONS = "FETCH_SUCCESS_PRESCRIPTIONS";
export const SET_ACTION_PRESCRIPTIONS = "SET_ACTION_PRESCRIPTIONS";
export const SET_PRESCRIPTIONS = "SET_PRESCRIPTIONS";

//HISTORIES
export const FETCH_ERROR_HISTORIES = "FETCH_ERROR_HISTORIES";
export const FETCH_START_HISTORIES = "FETCH_START_HISTORIES";
export const FETCH_SUCCESS_HISTORIES = "FETCH_SUCCESS_HISTORIES";
export const SET_ACTION_HISTORIES = "SET_ACTION_HISTORIES";
export const SET_HISTORIES = "SET_HISTORIES";

//VARIABLES
export const FETCH_START_VARIABLES = "FETCH_START_VARIABLES";
export const FETCH_SUCCESS_VARIABLES = "FETCH_SUCCESS_VARIABLES";
export const FETCH_ERROR_VARIABLES = "FETCH_ERROR_VARIABLES";
export const SET_VARIABLES = "SET_VARIABLES";
