import React from "react";
import { useSelector } from "react-redux";
import {Route, Switch, Redirect} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => {
  const {token, authUser} = useSelector(({auth}) => auth);

  return(
  <div className="gx-main-content-wrapper">
    {
      (token === null) ? 
      <Redirect to="/appointment" /> 
      : authUser ? authUser.af ? <Redirect to="/account" /> : null : null
    }
    <Switch>
      <Route path={`${match.url}appointment`} component={asyncComponent(() => import('./Home'))}/>
      <Route path={`${match.url}account`} component={asyncComponent(() => import('./User/Account'))}/>
      <Route path={`${match.url}order/:id/:horario?/:horas?`} component={asyncComponent(() => import('./User/Order'))}/>
      <Route path={`${match.url}doctor/profile/:id`} component={asyncComponent(() => import('./Doctor/Profile'))}/>
      <Route path={`${match.url}subaccounts`} component={asyncComponent(() => import('./User/Subaccounts'))}/>
      <Route path={`${match.url}profile/:id`} component={asyncComponent(() => import('./Doctor/Profile'))}/>
      <Route path={`${match.url}profileBusiness/:id`} component={asyncComponent(() => import('./Business/Profile'))}/>
      <Route path={`${match.url}prescriptions`} component={asyncComponent(() => import('./User/Prescriptions'))}/>
      <Route path={`${match.url}history`} component={asyncComponent(() => import('./User/History'))}/>
      <Route path={`${match.url}payments`} component={asyncComponent(() => import('./User/Payments'))}/>
      <Route path={`${match.url}bookings`} component={asyncComponent(() => import('./User/Bookings'))}/>
      <Route path={`${match.url}reschedule/:id`} component={asyncComponent(() => import('./User/Reprogramar'))}/>
    </Switch>
  </div>
  );
};

export default App;
