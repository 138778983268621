const languageData = [
  /* {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  }, */
  {
    languageId: 'spanish',
    locale: 'es',
    name: 'Ecuador',
    icon: 'ec',
    id: 1,
  },
  {
    languageId: 'spanish',
    locale: 'es',
    name: 'Colombia',
    icon: 'co',
    id: 2
  },
  {
    languageId: 'spanish',
    locale: 'es',
    name: 'Perú',
    icon: 'pe',
    id: 3
  }
  /* ,
  {
    languageId: 'chinese',
    locale: 'zh',
    name: 'Chinese',
    icon: 'cn'
  },
  {
    languageId: 'french',
    locale: 'fr',
    name: 'French',
    icon: 'fr'
  },
  {
    languageId: 'italian',
    locale: 'it',
    name: 'Italian',
    icon: 'it'
  },
  {
    languageId: 'saudi-arabia',
    locale: 'ar',
    name: 'Arabic',
    icon: 'sa'
  } */

];
export default languageData;
