import axios from "axios";
import router from "../routes/index";
const axioss = axios.create({
	// eslint-disable-next-line no-undef
	baseURL: `${process.env.REACT_APP_API}`,
	/* headers: {
    'Content-Type': 'application/json',
  } */
});

// Add a request interceptor
axioss.interceptors.request.use(
	config => {
		const token = JSON.parse(localStorage.getItem("token"));
		if (token) {
			config.headers["Authorization"] = "Bearer " + token;
			config.headers["Content-Type"] = "application/json";
		}
		// config.headers['Content-Type'] = 'application/json';
		return config;
	},
	error => {
		Promise.reject(error);
	});


axioss.interceptors.response.use( (response) => {
  
	// Return a successful response back to the calling service
	return response;
}, (error) => {
  
	// Return any error which is not due to authentication back to the calling service
	if (error.response.status !== 401) {
		return new Promise((resolve, reject) => {
			reject(error);
		});
	}

	// Logout user if token refresh didn't work or user is disabled
	if (error.config.url == "/api/token/refresh") {
      
		localStorage.removeItem("token");
		router.push({ name: "/signin" });

		return new Promise((resolve, reject) => {
			reject(error);
		});
	}

  
	return axioss.post("/token/refresh",
	).then(({data}) => {
		//console.log("menus: ", data.permissions);
		if (data.result) {
			localStorage.setItem("token", JSON.stringify(data.token.access_token));
			axios.defaults.headers.common["Authorization"] = "Bearer " + data.token.access_token;
			router.push({ name: "/" });
		}
	}).catch(function () {
	});
});


export default axioss;